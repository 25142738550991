@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  text-align: left !important;
}

:global(.image-gallery-thumbnails) {
  padding-top: 24px;
  padding-bottom: 0;

  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  width: 100vw;
  overflow: auto;

  @media (--viewportMedium) {
    width: calc(100vw - 48px);
  }

  @media (--viewportLarge) {
    width: unset;
  }
}

:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 8px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: var(--marketplaceColor);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--colorWhite);
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    border-radius: var(--borderRadiusMedium);
    border: 1px solid var(--colorGrey100);
    overflow: hidden;
  }
}

:global(.fullscreen) {
  background-color: var(--colorWhite) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

.root {}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper {
  border-radius: 20px;
  overflow: hidden;

  &>div {
    padding-bottom: 66.66% !important;

    @media (--viewportMedium) {
      padding-bottom: 40% !important;
    }
  }
}

.itemWrapperFullscreen {
  height: calc(100vh - 130px);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 88px;
  max-height: 88px;
  border-radius: var(--borderRadiusMedium);
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.openFullscreen {
  /* Hide on mobile. Currently react-image-gallery fullscreen mode is broken on
     mobile, and mobile doesn't really benefit from fullscreen anyways. */
  display: none;

  composes: buttonSmall from global;

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  @media (--viewportLarge) {
    display: block;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;

  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorBlack);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.singleImage {
  overflow: hidden;
  position: relative;
  border: 1px solid var(--colorGrey100);
  border-radius: 20px;
  padding-bottom: 66.66%;

  @media (--viewportMedium) {
    padding-bottom: 40%;
  }

  &>img,
  & .noImage {
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }
}

.gallarySlider {
  position: relative;

  & :global(.slick-arrow::before) {
    display: none;
  }

  & :global(.slick-arrow) {
    width: 30px;
    height: 30px;
    display: inline-flex;
    background-color: var(--colorWhite);
    align-items: center;
    justify-content: center;
    box-shadow: var(--boxShadowPopupLight);
    border-radius: 100px;
    z-index: 1;

    &:hover,
    &:focus {
      background-color: var(--marketplaceColor);
    }
  }

  & :global(.slick-arrow.slick-prev) {
    left: -10px;

    @media (--viewportLarge) {
      left: -16px;
    }
  }

  & :global(.slick-arrow.slick-next) {
    right: -10px;

    @media (--viewportLarge) {
      right: -16px;
    }
  }

  & :global(.slick-arrow.slick-prev.slick-disabled) {
    background-color: var(--colorGrey100);
  }

  & :global(.slick-arrow.slick-next.slick-disabled) {
    background-color: var(--colorGrey100);
  }

  & :global(.slick-arrow.slick-prev.slick-disabled:hover::before) {
    filter: invert(0);
  }

  & :global(.slick-arrow.slick-next.slick-disabled:hover::before) {
    filter: invert(0);
  }

  & :global(.slick-arrow.slick-prev.slick-disabled:active::before) {
    filter: invert(0);
  }

  & :global(.slick-arrow.slick-next.slick-disabled:active::before) {
    filter: invert(0);
  }

  & :global(.slick-arrow.slick-prev.slick-disabled:focus::before) {
    filter: none;
  }

  & :global(.slick-arrow.slick-next.slick-disabled:focus::before) {
    filter: none;
  }

  & :global(.slick-arrow.slick-prev:hover::before) {
    filter: invert(1);
  }

  & :global(.slick-arrow.slick-next:hover::before) {
    filter: invert(1);
  }

  & :global(.slick-arrow.slick-prev:active::before) {
    filter: invert(1);
  }

  & :global(.slick-arrow.slick-next:active::before) {
    filter: invert(1);
  }

  & :global(.slick-arrow.slick-prev:focus::before) {
    filter: invert(1);
  }

  & :global(.slick-arrow.slick-next:focus::before) {
    filter: invert(1);
  }
}

.gallaryDesktopSec {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 24px;
  min-height: 50vh;

  & .itemWrapper {
    &:first-child {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 5;
    }

    &:nth-child(2) {
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &:nth-child(3) {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &:nth-child(4) {
      grid-column-start: 5;
      grid-column-end: 7;
      grid-row-start: 3;
      grid-row-end: 5;
    }

    &:nth-child(5) {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 3;
      grid-row-end: 5;
    }
  }
}

.gallarySec {
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  & :global(.slick-list) {
    height: auto !important;
  }

  & :global(.slick-arrow) {
    position: absolute;
    z-index: 1;
    top: 50%;
    /* transform: translateY(-50%); */
    width: 30px;
    height: 30px;
    display: inline-flex;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    background-color: var(--colorWhite);
    border: solid 1px var(--colorWhite);
    transition: var(--transitionStyle);

    @media (--viewportSmall) {
      width: 40px;
      height: 40px;
    }

    &:hover {
      background-color: var(--colorGrey50);
      border: solid 1px var(--colorGrey500);
      transition: var(--transitionStyle);
    }
  }

  & :global(.slick-arrow > svg) {
    width: 20px;
    height: 20px;

    @media (--viewportSmall) {
      width: 30px;
      height: 30px;
    }
  }

  & :global(.slick-prev > svg) {
    transform: rotate(0deg);
  }

  & :global(.slick-next > svg) {
    transform: rotate(180deg);
  }

  & :global(.slick-prev::before),
  & :global(.slick-next::before) {
    display: none;
  }

  & :global(.slick-prev) {
    left: 10px;
  }

  & :global(.slick-next) {
    right: 10px;
  }

  & :global(.slick-dots) {
    position: absolute;
    bottom: 20px;
  }

  & :global(.slick-dots > li) {
    margin: 0;
  }

  & :global(.slick-dots > li > button) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--colorGrey100);
    border: none;
    margin: 0 6px 0 0;
  }

  & :global(.slick-dots > li.slick-active > button) {
    background-color: var(--marketplaceColor);
  }

  & :global(.slick-dots > li > button::before) {
    display: none;
  }
}

.viewPhotos {
  position: absolute;
  bottom: 18px;
  right: 10px;
  z-index: 2;

  &>button {
    padding: 8px 10px;
    line-height: 100%;
    min-height: auto;
    border-radius: 5px;
    height: auto;
    display: inline-block;
    min-height: auto;
    height: auto;
    background-color: var(--colorWhite);
    color: var(--marketplaceColor);

    @media (--viewportMedium) {
      padding: 8px 20px;
    }

    &:hover {
      color: var(--colorWhite);
    }
  }
}

.modalGallarySlider {
  width: 100%;

  & .gallaryDesktopSec {
    display: block;
    max-height: initial;
  }

  & .itemWrapper {
    &>div {
      padding-bottom: 66.66% !important;
    }
  }
}

.likeDislike {
  position: absolute;
  z-index: 1;
  background-color: var(--colorText);
  width: 50px;
  height: 50px;
  border-radius: var(--borderRadiusFull);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--boxShadow);
  top: 10px;
  left: 10px;
  cursor: pointer;

  &:hover {
    cursor: pointer;

    &>svg {
      & path {
        stroke: var(--marketplaceColor);
      }
    }
  }
}

.modalGallery {
  max-height: 85vh;
  width: 100%;
  overflow-y: auto;

  &>h2 {
    font-size: 18px;
    color: var(--colorGrey900);
    font-weight: var(--fontWeightMedium);
    line-height: 120%;
    margin-top: 0;
    padding: 0;
  }
}

.smallImagesWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 32px;

  @media (--viewportSmall) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(7, 1fr);
  }
}

.smallThumbnailImages {
  width: 100%;
  cursor: pointer;

  & .smallThumbnailGrid {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 100%;
    width: 100%;
    margin-bottom: 10px;

    & img {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  & .smallThumbnailInfos {
    font-size: 14px;
    font-weight: var(--fontWeightMedium);
    line-height: 120%;
    color: var(--colorGrey900);
  }
}

.largeImages {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .categoryImages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;

    @media (--viewportMedium) {
      gap: 20px;
      flex-direction: row;
    }

    & .categoryImagesTitle {
      width: 50%;
      font-size: 18px;
      font-weight: var(--fontWeightMedium);
      color: var(--colorGrey900);
      text-transform: capitalize;
    }

    & .largeThumbnailGrid {
      width: 100%;
      position: relative;
      padding-bottom: 66.66%;
      overflow: hidden;
      border-radius: 8px;

      @media (--viewportMedium) {
        padding-bottom: 40%;
      }

      @media (--viewportLarge) {
        padding-bottom: 34%;
      }

      &>div {
        width: 100%;
        height: 100%;

        & img {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}


& :global(.react-viewer-icon::before) {
  position: relative;
  bottom: 4px;
}

& :global(.react-viewer-mask) {
  background-color: rgb(0 0 0 / 92%);
}

.zoomerButtons{
  display: flex;
}